.VerticalStageBar{
  width: 100%;
  height: 100%;
  padding-top: 3rem;
}

.VerticalStageBar .item{
  width: 100%;
  height: 120px;
  display: grid;
  grid-template-columns: .1fr 1fr;
}
