.VerticalLine {
  width: 45px;
  height: 120px;
  position: absolute;
  z-index: -500;
}

.hidden{
  display: none;
  visibility: hidden;
  width: 0;
  height: 0;
}
