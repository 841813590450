* {
	font-family: 'Roboto', 'sans-serif';
}

html,
body {
	height: 100vh;
}
body {
	background-color: #f6f9fc;
	margin: 0;
}

#root {
	min-height: 100%;
	/* display: flex;
	flex-direction: column; */
}

:root {
	--primary: #5082c9;
	--success: #65ac28;
	--pink: #ff73c3;
	--orange: #ef851b;
	--text-dark: #565656;
	--text-grey: #c4c4c4;
	--text-grey2: #ebebeb;
	--error: #c95050;
	--grey-opacity: #e5e5e5;
}
