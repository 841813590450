.TextItem {
  width: 100%;
  height: 100%;

  display: grid;
  grid-template-rows: .1fr 1fr;

  padding-left: 1rem;
  color: var(--text-dark);
}

.TextItem .TextItem-titulo {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;

  margin-top: 15px;
}

.TextItem .TextItem-descripition {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  padding-top: .5rem;
}

.current {
  color: var(--primary);
}
